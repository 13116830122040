export const columns = [
  {
    Header: "Cuota",
    accessor: "Mes",
  },
  {
    Header: "Abono a capital",
    accessor: "Abono a capital",
  },
  {
    Header: "Costo financiero Toperty",
    accessor: "Pago de arriendo",
  },
  {
    Header: "Pago total",
    accessor: "Pago total",
  },
  {
    Header: "Rendimiento a favor usuario",
    accessor: "Ahorro rentas",
  },
  {
    Header: "Adquisición($)",
    accessor: "Ownership($)",
  },
  {
    Header: "Adquisición(%)",
    accessor: "Ownership(%)",
  },
];

export const financialColumns = [
  {
    Header: "Cuota",
    accessor: "Mes",
  },
  {
    Header: "Ingreso por arriendo",
    accessor: "Pago de arriendo",
  },
  {
    Header: "Venta parcial inmueble",
    accessor: "Abono a capital",
  },
  {
    Header: "Ingreso total",
    accessor: "Pago total",
  },
  {
    Header: "Costos",
    accessor: "Costos",
  },
  {
    Header: "Flujo distribuible",
    accessor: "Utilidades",
  },
];
